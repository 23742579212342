import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const DepartmentInput = ({ handleChanges, value }) => {
  return (
    <div className="inputContianer">
      <FormControl fullWidth>
        <InputLabel id="select-label">Department</InputLabel>
        <Select
          labelId="select-label"
          label="Department *"
          value={value}
          onChange={(e) => handleChanges(e, "department")}
          defaultValue={"general"}
        >
          <MenuItem value={"general"}>General</MenuItem>
          <MenuItem value={"BIMSolutions"}>BIM Solutions</MenuItem>
          <MenuItem value={"expertServices"}>Expert Services</MenuItem>
          <MenuItem value={"designBureau"}>Design Bureau</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default DepartmentInput;
