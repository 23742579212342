import React from "react";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Fab } from "@mui/material";

const fabStyle = {
  position: "fixed",
  bottom: 32,
  right: 32,
};

const ScrollButton = ({ finalRef }) => {
  const [offset, setOffset] = React.useState("still");
  const [width, setWidth] = React.useState("still");

  React.useEffect(() => {
    const onScroll = () => setOffset(window.scrollY <= 200 ? "still" : "moved");
    const onResize = () =>
      setWidth(window.innerWidth >= 1200 ? "big" : "small");

    onScroll();
    onResize();
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    window.removeEventListener("resize", onResize);
    window.addEventListener("resize", onResize, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const fab = {
    color: "primary",
    sx: { ...fabStyle },
    icon: <DownIcon />,
    label: "Expand",
  };

  return (
    <>
      {width === "small" && offset === "still" && (
        <Fab
          onClick={() =>
            finalRef.current.scrollIntoView({ behavior: "smooth" })
          }
          sx={fab.sx}
          aria-label={fab.label}
          color={fab.color}
        >
          {fab.icon}
        </Fab>
      )}
    </>
  );
};

export default ScrollButton;
