import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import countries from "../../common/locations";

const LocationInput = ({ value, handleSelect }) => {
  return (
    <div className="inputContianer">
      <Autocomplete
        id="country-select-demo"
        value={value}
        onChange={(event, newValue) => {
          handleSelect(newValue, "location");
        }}
        options={countries}
        autoHighlight
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option.label} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Location *"
            fullWidth
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
};

export default LocationInput;
