import React from "react";
import TextField from "@mui/material/TextField";

const TextInput = ({ handleChanges, value, valueName, label, InputProps }) => {
  return (
    <div className="inputContianer">
      <TextField
        value={value}
        onChange={(e) => handleChanges(e, valueName)}
        label={label}
        fullWidth
        InputProps={InputProps}
      />
    </div>
  );
};

export default TextInput;
