import React from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./helpModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 800,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  height: "80%",
  color: "#010039",
  overflowY: "scroll",
};

const HelpModal = ({ open, handleClose }) => {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <div>
          <h3>Steps for creating your own signature: </h3>
          <p className="step">
            1. Please fill in all the fields with your own details.
          </p>
          <div className="imgStepContainer">
            <img className="imgStep" src="step1.png" alt="step1"></img>
          </div>
          <p className="step">2. Make sure you selected your own department.</p>
          <div className="imgStepContainer">
            <img className="imgStep" src="step2.png " alt="step1"></img>
          </div>
          <p className="step">
            3. Double check the design for any spelling mistakes.
          </p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step3.png" alt="step3"></img>
          </div>
          <p className="step">4. Click the "Copy Signature" button.</p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step4.png" alt="step4"></img>
          </div>
          <p className="step">
            5. Open your BIM Ventures gmail account from{" "}
            <a href="https://mail.google.com/mail/u/0/#inbox">Gmail.com.</a>
            <p className="note">
              - Please make sure you're using your own BIM Ventures gmail
              account.
            </p>
            <p className="note">
              - You can also follow this{" "}
              <a href="https://www.youtube.com/watch?v=1C2Rt6kW96E">Link</a> for
              a video showing how to add your signature.
            </p>
          </p>

          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step5.png" alt="step5"></img>
          </div>
          <p className="step">6. Click on the settings icon.</p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step6.png" alt="step6"></img>
          </div>
          <p className="step">7. Click on "See all settings" button.</p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step7.png" alt="step7"></img>
          </div>
          <p className="step">
            8. Scroll down to the signature section and click on the "Create
            new" button.
          </p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step8.png" alt="step8"></img>
          </div>

          <p className="step">9. Give your signature a name.</p>
          <div className="imgStepContainer">
            <img className="verticalImgStep" src="step9.png" alt="step9"></img>
          </div>

          <p className="step">
            10. Paste your copied signature in the empty field.
          </p>
          <div className="imgStepContainer">
            <img
              className="verticalImgStep"
              src="step10.png"
              alt="step10"
            ></img>
          </div>

          <p className="step">
            11. Change the default signature settings if needed.
          </p>
          <div className="imgStepContainer">
            <img
              className="verticalImgStep"
              src="step11.png"
              alt="step11"
            ></img>
          </div>

          <p className="step">
            12. Scroll down to the end and click on the saved button.
          </p>
          <div className="imgStepContainer">
            <img
              className="verticalImgStep"
              src="step12.png"
              alt="step12"
            ></img>
          </div>

          <div className="midInputContianer ">
            <Button
              color="success"
              variant="contained"
              type="click"
              onClick={handleClose}
            >
              Done
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default HelpModal;
