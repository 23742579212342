import React from "react";

const TopTextSection = ({ details }) => {
  const firstWordIndex = Math.min(
    details.positionE.indexOf(" ") === -1
      ? 10000
      : details.positionE.indexOf(" "),
    details.positionE.indexOf("-") === -1
      ? 10000
      : details.positionE.indexOf("-")
  );
  return (
    <>
      <p
        style={{
          color:
            details.department === "BIMSolutions"
              ? "#7662ED"
              : details.department === "expertServices"
              ? "#024DFF"
              : details.department === "designBureau"
              ? "#FFC003"
              : "#00D5FD",
          margin: "6px 6px 0px 0px",
          fontSize: "13px",
          fontWeight: "700",
        }}
      >
        {details.fullNameE}{" "}
        {details.fullNameE && details.fullNameA && <span>|</span>}{" "}
        {details.fullNameA}
      </p>

      <p
        // className="phone"
        style={{
          color: "#010039",
          fontSize: "11px",
          margin: "0",
          marginTop: "2px",
          fontWeight: "700",
        }}
      >
        {details.positionE}{" "}
        {details.positionE && details.positionA && <span>|</span>}{" "}
        {details.positionA}
      </p>

      <p
        // className="phone"
        style={{
          color: "#010039",
          fontSize: "11px",
          // fontWeight: "700",
          margin: "0",
          marginTop: "3px",
        }}
      >
        +{details.location.phone}{" "}
        {details.location.phone.length === 3
          ? ` ${details.phoneNumber.slice(0, 2)} 
                ${details.phoneNumber.slice(2, 5)} 
                ${details.phoneNumber.slice(5, 9)}`
          : ` ${details.phoneNumber.slice(0, 3)} 
                ${details.phoneNumber.slice(3, 6)} 
                ${details.phoneNumber.slice(6, 10)}`}
      </p>
      <p
        style={{
          fontSize: "9px",
          // fontWeight: "700",
          color: "rgb(1,0,57)",
          margin: "0",
        }}
      >
        <a
          // className="links"
          style={{
            textDecorationLine: "none",
            fontSize: "9px",
            // fontWeight: "700",
          }}
          href="https://bimventures.com/"
        >
          BIMventures.com
        </a>{" "}
        |{" "}
        <a
          // className="links"
          style={{
            textDecorationLine: "none",
            fontSize: "9px",
            // fontWeight: "700",
          }}
          href="https://twitter.com/bim_ventures"
        >
          @BIMventures
        </a>
      </p>
    </>
  );
};

export default TopTextSection;
